import { $http } from "@/plugins/api.js";

const state = {
  list: [],
  item: {},
};

const getters = {
  getList: (state) => state.list,
  getItem: (state) => state.item,
};

const actions = {
  async updateQuarantineReport(
    { commit },
    { type_id = null, type = null, formData }
  ) {
    const { data } = await $http.post(
      `/restapi/${type}/${type_id}/ui/quarantine`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  },
  async getQuarantineReport({ commit }, { type_id = null, type = null }) {
    const { data } = await $http.get(
      `/restapi/${type}/${type_id}/ui/quarantine`
    );
    return data;
  },
  async deleteQuarantineReport({ commit }, { type_id = null, type = null }) {
    const { data } = await $http.delete(
      `/restapi/${type}/${type_id}/ui/quarantine`
    );
    return data;
  },
};

const mutations = {
  setList: (state, list) => (state.list = list),
  setItem: (state, item) => (state.item = item),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

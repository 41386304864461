const state = {
  title: null,
  subTitle: null,
  mspName: "msp",
  customerName: "customer",
  domainName: "domain",
  userName: "user",
  parentPath: "",
};

const getters = {
  pageMeta: (state) => state,
};

const actions = {
  setPageTitle({ commit }, title) {
    document.title = this.state.userInterface.title + ` | ${title}`;
    commit("setPageTitle", title);
  },
  /**
   * @param commit
   * @param {string[]} subTitle Array of subtitles, each set to separate p tag
   */
  setPageSubtitle({ commit }, subTitle) {
    commit("setPageSubtitle", subTitle);
  },

  setParentPath({ commit }, path) {
    commit("setParentPath", path);
  },

  setMSP({ commit }, name) {
    commit("setMSP", name);
  },
  setCustomer({ commit }, name) {
    commit("setCustomer", name);
  },
  setDomain({ commit }, name) {
    commit("setDomain", name.domain || name.name);
  },
  setUser({ commit }, name) {
    commit("setUser", name);
  },
};

const mutations = {
  setPageTitle: (state, title) => (state.title = title),
  setPageSubtitle: (state, subTitle) => (state.subTitle = subTitle),
  setMSP: (state, name) => (state.mspName = name),
  setCustomer: (state, name) => (state.customerName = name),
  setDomain: (state, name) => (state.domainName = name),
  setUser: (state, name) => (state.userName = name),
  setParentPath: (state, path) => (state.parentPath = path),
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

import { $http } from "@/plugins/api.js";

const state = {
  msps: [],
  fetchingCustomers: false,
  selected: null,
  mspCustomers: [], // aka Domain-Groups, which is attached to specific MSP
  selectedMspCustomer: null,
  serverTotal: 0,
  currentPaginatedResults: 0,
  currentSortedBy: null,
  currentSortedDir: null,
  fetchingNavList: false,
};

const getters = {
  msps: (state) => state.msps,
  fetchingCustomers: (state) => state.fetchingCustomers,
  selected: (state) => state.selected,
  mspCustomers: (state) => state.mspCustomers,
  selectedMspCustomer: (state) => state.selectedMspCustomer,
  serverTotal: (state) => state.serverTotal,
  currentSortedBy: (state) => state.currentSortedBy,
  currentSortedDir: (state) => state.currentSortedDir,
  fetchingNavList: (state) => state.fetchingNavList,
};

const actions = {
  async fetchMsp({ commit }, { id }) {
    const response = await $http.get(`/restapi/msps/${id}`);
    commit("setSelected", response.data);
    return response;
  },
  async fetchCustomer({ commit }, { id }) {
    const response = await $http.get(`/restapi/domain-groups/${id}`);
    commit("setSelected", response.data);
    return response;
  },
  async fetchMsps(
    { commit },
    { results = 10, page = 1, sortBy = "name", sortDir = "desc", filter = null }
  ) {
    let requestURL = "/restapi/msps";

    if (results) requestURL += `?results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;

    const response = await $http.get(requestURL);
    const msps = response.data.data.map((msp) => {
      return { ...msp, accountname: msp.name };
    });
    commit("setMsps", msps);
    commit("setServerTotal", response.data.total);
    commit("setCurrentPaginatedResults", results);
    commit("setCurrentSortedBy", sortBy);
    commit("setCurrentSortedDir", sortDir);
  },

  async fetchAllMsps({ state, commit }) {
    commit("setFetchingNavList", true);
    let requestURL = "/restapi/msps";
    requestURL += `?sortkey=${state.currentSortedBy}&sortdir=${state.currentSortedDir}`;
    const response = await $http.get(requestURL);
    commit("setFetchingNavList", false);
    return response.data;
  },

  async createMsp({ commit }, { formData }) {
    const { name, description } = formData;
    const res = await $http.post("/restapi/msps", {
      name,
      description,
    });

    commit("addMsp", {
      id: res.data.id,
      name: res.data.name,
      accountname: res.data.name,
      description: res.data.description,
      created_at: res.data.created_at,
    });

    return res;
  },

  async updateMsp({ commit }, { formData, id }) {
    const url = `/restapi/msps/${id}`;
    const response = await $http.put(url, formData);

    commit("updateMsp", response.data);
  },

  async batchDeleteMsps({ commit }, { msps }) {
    const entries = [];

    for (const msp of msps) {
      const key = `${msp.id}`;
      const value = {
        method: "delete",
        path: `/msps/${msp.id}`,
      };
      entries.push([key, value]);
    }

    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));
    const ids = Object.keys(res.data).map((item) => +item);
    commit("deleteMsps", ids);
  },

  async fetchMspCustomers(
    { commit },
    {
      mspId,
      results = 10,
      page = 1,
      sortBy = "name",
      sortDir = "desc",
      filter = null,
    }
  ) {
    let requestURL = `/restapi/msps/${mspId}/domain-groups`;

    if (results) requestURL += `?results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;

    commit("setFetchingCustomers", true);
    const response = await $http.get(requestURL);
    const domainGroups = response.data.data.map((dg) => {
      return { ...dg, accountname: dg.name };
    });
    commit("setMspCustomers", domainGroups);
    commit("setServerTotal", response.data.total);
    commit("setFetchingCustomers", false);
    commit("setCurrentPaginatedResults", results);
    commit("setCurrentSortedBy", sortBy);
    commit("setCurrentSortedDir", sortDir);
  },

  async fetchAllMspCustomers({ state, commit }, { mspId }) {
    commit("setFetchingNavList", true);
    let requestURL = `/restapi/msps/${mspId}/domain-groups`;
    requestURL += `?sortkey=${state.currentSortedBy}&sortdir=${state.currentSortedDir}`;
    const response = await $http.get(requestURL);
    commit("setFetchingNavList", false);
    return response.data;
  },

  async fetchMspCustomer({ commit }, { customerId }) {
    const response = await $http.get(`/restapi/domain-groups/${customerId}`);
    commit("setSelectedMspCustomer", response.data);
  },

  async createMspCustomer({ commit }, { formData }) {
    const { mspId, name, description } = formData;

    const res = await $http.post(`/restapi/msps/${mspId}/domain-groups`, {
      name,
      description,
    });

    commit("addMspCustomer", {
      id: res.data.id,
      name: res.data.name,
      accountname: res.data.name,
      description: res.data.description,
      created_at: res.data.created_at,
    });

    return res;
  },

  async updateMspCustomer({ commit }, { customerId, formData }) {
    const url = `/restapi/domain-groups/${customerId}`;
    const response = await $http.put(url, formData);
    commit("updateMspCustomer", response.data);
  },

  async batchDeleteMspCustomers({ commit }, { customers }) {
    const req = {};

    for (const customer of customers) {
      req[`${customer.id}`] = {
        method: "delete",
        path: `/domain-groups/${customer.id}`,
      };
    }

    commit("setFetchingCustomers", true);

    const res = await $http.post("/restapi/batch", req);
    const ids = Object.keys(res.data).map((item) => +item);
    commit("deleteMspCustomers", ids);
    commit("setFetchingCustomers", false);
  },

  clearList({ commit }) {
    commit("setMsps", []);
    commit("setMspCustomers", []);
  },
};

const mutations = {
  setSelected: (state, data) => (state.selected = data),
  setMsps: (state, data) => (state.msps = data),
  setFetchingCustomers: (state, payload) => (state.fetchingCustomers = payload),
  setServerTotal: (state, payload) => (state.serverTotal = payload),
  setCurrentPaginatedResults: (state, payload) =>
    (state.currentPaginatedResults = payload),
  addMsp: (state, data) => {
    if (state.serverTotal < state.currentPaginatedResults) {
      state.msps.push(data);
    }
    state.serverTotal = state.serverTotal + 1;
  },
  updateMsp: (state, data) => (state.msps[`${data.idx}`] = data),
  deleteMsps(state, ids) {
    for (const id of ids) {
      state.msps = state.msps.filter((msp) => msp.id !== id);
    }
  },
  addMspCustomer: (state, data) => state.mspCustomers.push(data),
  setMspCustomers: (state, data) => (state.mspCustomers = data),
  setSelectedMspCustomer: (state, data) => (state.selectedMspCustomer = data),
  updateMspCustomer: (state, data) =>
    (state.mspCustomers[`${data.idx}`] = data),
  deleteMspCustomers(state, ids) {
    for (const id of ids) {
      state.mspCustomers = state.mspCustomers.filter(
        (customer) => customer.id !== id
      );
      state.serverTotal = state.serverTotal - 1;
    }
  },

  setCurrentSortedBy: (state, payload) => (state.currentSortedBy = payload),
  setCurrentSortedDir: (state, payload) => (state.currentSortedDir = payload),
  setFetchingNavList: (state, payload) => (state.fetchingNavList = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { computed } from "vue";
import { useCookies } from "@vueuse/integrations/useCookies";

import { COOKIE_SETTINGS } from "@/utils/cookies";
import { SideNavItemStatus, COMING_SOON, SideNavPill } from "@/types/side-nav";

const cookies = useCookies();
const NEW_FEATURES = "new-features";

export const modalSeenCookieName = "v8_graymailModalSeen";
export const pageVisitedCookieName = "v8_graymailPageVisited";

type Feature = typeof modalSeenCookieName | typeof pageVisitedCookieName;

export const newFeatureModalSeenCookie = computed(
  () => cookies.get(NEW_FEATURES)?.[modalSeenCookieName]
);

export const newFeaturePageVisitedCookie = computed(
  () => cookies.get(NEW_FEATURES)?.[pageVisitedCookieName]
);

export const setCookie = (feature: Feature) =>
  cookies.set(
    NEW_FEATURES,
    {
      ...cookies.get(NEW_FEATURES),
      [feature]: true,
    },
    COOKIE_SETTINGS
  );
export const getSideNavItemPill = (status: SideNavItemStatus): SideNavPill => {
  if (status === COMING_SOON) {
    return { variant: "info", text: "Coming soon" };
  }
  return { variant: "success", text: "New" };
};

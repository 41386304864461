import { i18n } from "@/i18n";

import {
  newFeaturePageVisitedCookie,
  getSideNavItemPill,
} from "@/utils/new-features-announcement.ts";

const mspLinks = [
  {
    label: i18n.global.t("Overview"),
    dataTestId: "navbar-overview",
    icon: "cl-icon-tabler-dashboard",
    exact: true,
    id: "/msp/overview",
  },
  {
    label: i18n.global.t("History"),
    dataTestId: "navbar-realtime-history",
    icon: "cl-icon-tabler-history",
    id: "/msp/history",
  },
  {
    label: i18n.global.t("Quarantine"),
    dataTestId: "navbar-realtime-quarantine",
    icon: "cl-icon-tabler-shield-lock",
    exact: true,
    id: "/msp/quarantine",
  },
  {
    label: i18n.global.t("Filtering"),
    dataTestId: "navbar-filtering",
    icon: "cl-icon-tabler-arrow-fork-left",
    id: "/msp/filtering/",
    children: [
      {
        label: i18n.global.t("Allow & Block List"),
        dataTestId: "navbar-content-filtering-block-allow-list",
        id: "/msp/filtering/allow_block_list",
      },
      {
        label: i18n.global.t("Geoblocking"),
        dataTestId: "navbar-geoblocking",
        id: "/msp/filtering/geoblocking",
      },
      {
        label: i18n.global.t("Link Lock"),
        dataTestId: "navbar-link-lock",
        id: "/msp/filtering/link_lock",
      },
      {
        label: i18n.global.t("Pattern Filtering"),
        dataTestId: "navbar-pattern-filtering",
        id: "/msp/filtering/pattern_filtering",
      },
    ],
  },
  {
    label: i18n.global.t("Settings"),
    dataTestId: "navbar-settings",
    icon: "cl-icon-tabler-settings",
    id: "/msp/settings",
    pill: "NEW",
    children: [
      {
        label: i18n.global.t("Administrators"),
        dataTestId: "navbar-administrators",
        id: "/msp/settings/administrators",
      },
      {
        label: i18n.global.t("Two-Factor Authentication"),
        dataTestId: "navbar-twoFactorAuth",
        id: "/msp/settings/two_factor_authentication",
        pill: !newFeaturePageVisitedCookie.value
          ? getSideNavItemPill("NEW")
          : "",
      },
      {
        label: i18n.global.t("Interface"),
        dataTestId: "navbar-interface",
        id: "/msp/settings/interface",
      },
      {
        label: i18n.global.t("Quarantine Report"),
        dataTestId: "navbar-quarantine-reprot",
        id: "/msp/settings/quarantine_report",
      },
      {
        label: i18n.global.t("Support"),
        dataTestId: "navbar-support",
        id: "/msp/settings/support",
      },
    ],
  },
];

const customerAdminLinks = [
  {
    label: i18n.global.t("Overview"),
    dataTestId: "navbar-overview",
    icon: "cl-icon-tabler-dashboard",
    exact: false,
    id: "/customer/overview",
  },
  {
    label: i18n.global.t("History"),
    dataTestId: "navbar-realtime-history",
    icon: "cl-icon-tabler-history",
    id: "/customer/history",
  },
  {
    label: i18n.global.t("Quarantine"),
    dataTestId: "navbar-quarantine",
    icon: "cl-icon-tabler-shield-lock",
    id: "/customer/quarantine_report",
  },
  {
    label: i18n.global.t("Filtering"),
    dataTestId: "navbar-filtering",
    icon: "cl-icon-tabler-arrow-fork-left",
    id: "/customer/filtering",
    children: [
      {
        label: i18n.global.t("Anti-Spoof"),
        dataTestId: "navbar-content-filtering-anti-spoof",
        id: "/customer/filtering/anti_spoof",
      },
      {
        label: i18n.global.t("Attachments"),
        dataTestId: "navbar-content-filtering-attachments",
        id: "/customer/filtering/attachments",
      },
      {
        label: i18n.global.t("Allow & Block List"),
        dataTestId: "navbar-content-filtering-block-allow-list",
        id: "/customer/filtering/allow_block_list",
      },
      {
        label: i18n.global.t("Geoblocking"),
        dataTestId: "navbar-geoblocking",
        id: "/customer/filtering/geoblocking",
      },
      {
        label: i18n.global.t("Link Lock"),
        dataTestId: "navbar-link-lock",
        id: "/customer/filtering/link_lock",
      },
      {
        label: i18n.global.t("Pattern Filtering"),
        dataTestId: "navbar-pattern-filtering",
        id: "/customer/filtering/pattern_filtering",
      },
    ],
  },
  {
    label: i18n.global.t("Policies"),
    dataTestId: "navbar-policies",
    icon: "cl-icon-tabler-shield-lock",
    id: "/customer/policies",
    children: [
      {
        label: i18n.global.t("Domain Policies"),
        dataTestId: "navbar-domains-policies",
        id: "/customer/policies/domain_policies",
      },
    ],
  },
  {
    label: i18n.global.t("Reporting"),
    dataTestId: "navbar-domain-group-reporting",
    icon: "cl-icon-tabler-report-analytics",
    id: "/customer/reporting/",
    children: [
      {
        label: i18n.global.t("Scheduled"),
        dataTestId: "navbar-customer-scheduled",
        id: "/customer/reporting/scheduled",
      },
      {
        label: i18n.global.t("On Demand"),
        dataTestId: "navbar-customer-on-demand",
        id: "/customer/reporting/on-demand",
      },
      {
        label: i18n.global.t("Archive"),
        dataTestId: "navbar-customer-archive",
        id: "/customer/reporting/archive",
      },
    ],
  },
  {
    label: i18n.global.t("Settings"),
    dataTestId: "navbar-settings",
    icon: "cl-icon-tabler-settings",
    id: "/customer/settings",
    pill: "NEW",
    children: [
      {
        label: i18n.global.t("Administrators"),
        dataTestId: "navbar-settings-administrators",
        id: "/customer/settings/administrators",
      },
      {
        label: i18n.global.t("Two-Factor Authentication"),
        dataTestId: "navbar-customer-settings-twoFactorAuth",
        id: "/customer/settings/two_factor_authentication",
        pill: !newFeaturePageVisitedCookie.value
          ? getSideNavItemPill("NEW")
          : "",
      },
    ],
  },
];

const multiDomainAdminLinks = [
  {
    label: i18n.global.t("Overview"),
    dataTestId: "navbar-overview",
    icon: "cl-icon-tabler-dashboard",
    exact: true,
    id: "/multi_domain_administrator/overview",
  },
  {
    label: i18n.global.t("Realtime Traffic"),
    dataTestId: "navbar-realtime-traffic",
    icon: "cl-icon-tabler-history",
    exact: true,
    id: "/multi_domain_administrator/realtime_traffic",
  },
  {
    label: i18n.global.t("Filtering"),
    dataTestId: "navbar-filtering",
    icon: "cl-icon-tabler-arrow-fork-left",
    exact: true,
    id: "/multi_domain_administrator/filtering/",
  },
  {
    label: i18n.global.t("Anti Spam Engine"),
    dataTestId: "navbar-anti-spam-engine",
    icon: "cl-icon-tabler-shield-check",
    exact: true,
    id: "/multi_domain_administrator/anti_spam_engine",
  },
  {
    label: i18n.global.t("Quarantine"),
    dataTestId: "navbar-quarintine",
    icon: "cl-icon-tabler-shield-lock",
    exact: true,
    id: "/multi_domain_administrator/quarintine",
  },
  {
    label: i18n.global.t("Settings"),
    dataTestId: "navbar-settings",
    icon: "cl-icon-tabler-settings",
    exact: true,
    id: "/multi_domain_administrator/settings",
  },
];

const domainAdminLinks = [
  {
    label: i18n.global.t("Overview"),
    dataTestId: "navbar-domain-overview",
    icon: "cl-icon-tabler-dashboard",
    exact: true,
    id: "/domain/overview",
  },
  {
    label: i18n.global.t("History"),
    dataTestId: "navbar-realtime-history",
    icon: "cl-icon-tabler-history",
    id: "/domain/history",
  },
  {
    label: i18n.global.t("Quarantine"),
    dataTestId: "navbar-domain-quarantine",
    icon: "cl-icon-tabler-shield-lock",
    exact: true,
    id: "/domain/quarantine",
  },
  {
    label: i18n.global.t("Filtering"),
    dataTestId: "navbar-domain-filtering",
    icon: "cl-icon-tabler-arrow-fork-left",
    id: "/domain/filtering/",
    children: [
      {
        label: i18n.global.t("Anti-Spoof"),
        dataTestId: "navbar-content-filtering-anti-spoof",
        id: "/domain/filtering/anti_spoof",
      },
      {
        label: i18n.global.t("Attachments"),
        dataTestId: "navbar-user-content-filtering-attachments",
        id: "/domain/filtering/attachments",
      },
      {
        label: i18n.global.t("Allow & Block List"),
        dataTestId: "navbar-user-content-filtering-block-allow-list",
        id: "/domain/filtering/allow_block_list",
      },
      {
        label: i18n.global.t("Geoblocking"),
        dataTestId: "navbar-user-content-filtering-geoblocking",
        id: "/domain/filtering/geoblocking",
      },
      {
        label: i18n.global.t("Link Lock"),
        dataTestId: "navbar-link-lock",
        id: "/domain/filtering/link_lock",
      },
      {
        label: i18n.global.t("Pattern Filtering"),
        dataTestId: "navbar-pattern-filtering",
        id: "/domain/filtering/pattern_filtering",
      },
    ],
  },
  {
    label: i18n.global.t("Policies"),
    dataTestId: "navbar-policies",
    icon: "cl-icon-tabler-shield-lock",
    id: "/domain/policies",
    children: [
      {
        label: i18n.global.t("Domain Policy"),
        dataTestId: "navbar-domain-policy",
        id: "/domain/policies/domain_policy",
      },
      {
        label: i18n.global.t("User Policies"),
        dataTestId: "navbar-user-policies",
        id: "/domain/policies/user_policies",
      },
    ],
  },
  {
    label: i18n.global.t("Reporting"),
    dataTestId: "navbar-domain-reporting",
    icon: "cl-icon-tabler-report-analytics",
    id: "/domain/reporting/",
    children: [
      {
        label: i18n.global.t("Scheduled"),
        dataTestId: "navbar-customer-scheduled",
        id: "/domain/reporting/scheduled",
      },
      {
        label: i18n.global.t("On Demand"),
        dataTestId: "navbar-customer-on-demand",
        id: "/domain/reporting/on-demand",
      },
      {
        label: i18n.global.t("Archive"),
        dataTestId: "navbar-customer-archive",
        id: "/domain/reporting/archive",
      },
    ],
  },
  {
    label: i18n.global.t("Settings"),
    dataTestId: "navbar-settings",
    icon: "cl-icon-tabler-settings",
    id: "/domain/settings",
    children: [
      {
        label: i18n.global.t("Administrators"),
        dataTestId: "domain-navbar-settings-administrators",
        id: "/domain/settings/administrators",
      },
    ],
  },
];

const userLinks = [
  {
    label: i18n.global.t("History"),
    dataTestId: "navbar-realtime-history",
    icon: "cl-icon-tabler-history",
    id: "/user/history",
  },
  {
    label: i18n.global.t("Quarantine"),
    dataTestId: "navbar-user-Quarantine",
    icon: "cl-icon-tabler-shield-lock",
    id: "/user/quarantine",
  },
  {
    label: i18n.global.t("Filtering"),
    dataTestId: "navbar-user-filtering",
    icon: "cl-icon-tabler-arrow-fork-left",
    id: "/user/filtering/",
    children: [
      {
        label: i18n.global.t("Allow & Block List"),
        dataTestId: "navbar-user-content-filtering-block-allow-list",
        id: "/user/filtering/allow_block_list",
      },
      {
        label: i18n.global.t("Geoblocking"),
        dataTestId: "navbar-user-content-filtering-geoblocking",
        id: "/user/filtering/geoblocking",
      },
    ],
  },
];

const systemMenuItems = [
  {
    label: "Overview",
    dataTestId: "navbar-overview",
    icon: "cl-icon-tabler-dashboard",
    exact: true,
    id: "/system/overview",
  },
  {
    label: i18n.global.t("Filtering"),
    dataTestId: "navbar-filtering",
    icon: "cl-icon-tabler-arrow-fork-left",
    id: "/system/filtering/",
    children: [
      {
        label: i18n.global.t("Allow & Block List"),
        dataTestId: "navbar-content-filtering-block-allow-list",
        id: "/system/filtering/allow_block_list",
      },
      {
        label: i18n.global.t("Pattern Filtering"),
        dataTestId: "navbar-pattern-filtering",
        id: "/system/filtering/pattern_filtering",
      },
    ],
  },
  {
    label: i18n.global.t("Settings"),
    dataTestId: "navbar-settings",
    icon: "cl-icon-tabler-settings",
    id: "/system/settings",
    children: [
      {
        label: i18n.global.t("Administrators"),
        dataTestId: "navbar-administrators",
        id: "/system/settings/administrators",
      },
    ],
  },
];

const profileMenuLinks = [
  {
    label: "Security",
    dataTestId: "navbar-security",
    icon: "cl-icon-shield-check",
    exact: true,
    id: "/profile/security",
  },
];

export default {
  install(app) {
    app.config.globalProperties.$getPrimaryNavItems = (type) => {
      switch (type) {
        case "system":
          return systemMenuItems;
        case "msp":
          return mspLinks;
        case "customer_admin":
          return customerAdminLinks;
        case "multi_domain_admin":
          return multiDomainAdminLinks;
        case "domain_admin":
          return domainAdminLinks;
        case "end_user":
          return userLinks;
        case "profile":
          return profileMenuLinks;
      }
    };
  },
};

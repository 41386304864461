var idSite = 3;
var matomoTrackingApiUrl = "https://titanhq.matomo.cloud/matomo.php";

var _paq = (window._paq = window._paq || []);
_paq.push(["setTrackerUrl", matomoTrackingApiUrl]);
_paq.push(["setSiteId", idSite]);
_paq.push(["trackPageView"]);
_paq.push(["enableLinkTracking"]);

var _mtm = (window._mtm = window._mtm || []);
_mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });

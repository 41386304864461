import { createRouter } from "vue-router";

import MspLayout from "@/layouts/Msp/MspLayout.vue";
import CustomerLayout from "@/layouts/Customer/CustomerLayout.vue";
import DomainLayout from "@/layouts/Domain/DomainLayout.vue";
import UserLayout from "@/layouts/EndUser/EndUserLayout.vue";
import SalesLayout from "@/layouts/Sales/SalesLayout.vue";
import SystemLayout from "@/layouts/System/SystemLayout.vue";
import ProfileLayout from "@/layouts/Profile/ProfileLayout.vue";
import AuthLayout from "@/layouts/Auth/AuthLayout.vue";
import NotFoundPage from "@/views/NotFoundPage/NotFoundPage";

import mspRoutes from "./routes/mspRoutes";
import customerRoutes from "./routes/customerRoutes";
import domainRoutes from "./routes/domainRoutes";
import userRoutes from "./routes/userRoutes";
import systemRoutes from "./routes/systemRoutes";
import profileRoutes from "./routes/profileRoutes";
import authRoutes from "./routes/authRoutes";
import { ACCOUNT_TYPE } from "@/constants";

import { createWebHistory } from "vue-router";
import { store } from "../store/index";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    component: NotFoundPage,
    redirect: {
      name: "auth-sign-in",
    },
  },
  {
    path: "/password/forgot",
    component: NotFoundPage,
    redirect: {
      name: "auth-password-set",
    },
  },
  {
    path: "/auth",
    component: AuthLayout,
    children: authRoutes,
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/Auth/Logout"),
  },
  {
    path: "/msp",
    component: MspLayout,
    children: mspRoutes,
    meta: {
      authorize: [
        ACCOUNT_TYPE.SYSTEM,
        ACCOUNT_TYPE.SALES,
        ACCOUNT_TYPE.MSP,
        ACCOUNT_TYPE.CUSTOMER,
      ],
    },
  },
  {
    path: "/customer",
    component: CustomerLayout,
    children: customerRoutes,
    meta: {
      authorize: [
        ACCOUNT_TYPE.SYSTEM,
        ACCOUNT_TYPE.SALES,
        ACCOUNT_TYPE.MSP,
        ACCOUNT_TYPE.CUSTOMER,
      ],
    },
  },
  {
    path: "/domain",
    component: DomainLayout,
    children: domainRoutes,
    meta: {
      authorize: [
        ACCOUNT_TYPE.SYSTEM,
        ACCOUNT_TYPE.SALES,
        ACCOUNT_TYPE.MSP,
        ACCOUNT_TYPE.CUSTOMER,
        ACCOUNT_TYPE.DOMAIN,
      ],
    },
  },
  {
    path: "/user",
    component: UserLayout,
    children: userRoutes,
  },
  {
    path: "/system",
    component: SystemLayout,
    children: systemRoutes,
    meta: {
      authorize: [ACCOUNT_TYPE.SYSTEM, ACCOUNT_TYPE.SALES],
    },
  },
  {
    path: "/sales",
    component: SalesLayout,
    meta: {
      authorize: [ACCOUNT_TYPE.SYSTEM, ACCOUNT_TYPE.SALES],
    },
    children: [
      {
        path: "",
        component: () => import("../views/Sales/App.vue"),
      },
    ],
  },
  {
    path: "/profile",
    component: ProfileLayout,
    children: profileRoutes,
    meta: {
      authorize: [
        ACCOUNT_TYPE.SYSTEM,
        ACCOUNT_TYPE.SALES,
        ACCOUNT_TYPE.MSP,
        ACCOUNT_TYPE.DOMAIN,
        ACCOUNT_TYPE.USER,
        ACCOUNT_TYPE.CUSTOMER,
      ],
    },
  },
  {
    path: "/restapi/password/reset",
    redirect: (to) => {
      return { name: "auth-password-set", query: { token: to.query.token } };
    },
  },
];

export const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
  base: import.meta.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});
router.beforeEach((to, _from, next) => {
  try {
    const userRole =
      store.getters["authentication/selectedAccount"]?.account_type || null;

    if (to.matched.length === 0) {
      console.error("Page not found:", to.fullPath);
      return next({ name: "auth-sign-in" });
    }

    const allRoles = to.matched.flatMap((route) => route.meta.authorize || []);

    if (allRoles.length > 0 && (!userRole || !allRoles.includes(userRole))) {
      console.warn("Unauthorized access:", to.fullPath);
      return next({ name: "auth-sign-in" });
    }

    next();
  } catch (err) {
    console.error("Routing error:", err);
    next({ name: "auth-sign-in" });
  }
});

export default {};
